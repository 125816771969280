import React from 'react';
import { Steps } from 'antd';
import { connect } from 'react-redux'

import DefaultLayout from '../../layouts/DefaultLayout';

import Applicant from '../../components/ResidenceApplicant';
import ResidenceInfo from '../../components/ResidenceInfo';
import ResidenceConfirm from '../../components/ResidenceConfirm';
import Success from '../../components/SuccessPage';

const Step = Steps.Step;

const steps = [{
    title: '填写基本信息',
    content: '填写基本信息'
}, {
    title: '填写居住信息',
        content: '填写居住信息'
}, {
    title: '确认',
    content: '确认信息'
}, {
    title: '完成',
    content: '入户登记完成'
}]

class ResidenceRegister extends React.Component {
    render() {
        let { current, status } = this.props;

        return (
            <DefaultLayout title="居住证申领">
                <div style={{ maxWidth: "720px", padding: "5px", margin: "0 auto" }}>
                    <Steps current={current} status={status} style={{ marginBottom: 10 }}>
                        {steps.map(item => <Step key={item.title} title={item.title} />)}
                    </Steps>
                    <div>
                        {
                            current === 0
                            && <Applicant />
                        }

                        {
                            current === 1
                            && <ResidenceInfo />
                        }

                        {
                            current === 2
                            && <ResidenceConfirm/>
                        }

                        {
                            current===3
                            && <Success/>
                        }
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(ResidenceRegister);