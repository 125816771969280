import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Navigation } from 'react-router-dom';
import { Provider } from 'react-redux';

import logo from './logo.svg';
import { type } from 'os';

import './App.css';

import Home from './pages/home';
import Newborn from './pages/forms/newborn';
import HouseholdRegister from './pages/forms/householdregister';
import Deregister from './pages/forms/householdderegister';
import Immigration from './pages/forms/immigration';

import HouseholdModify from './pages/forms/householdmodify';

import Residence from './pages/forms/residenceregister';

import registerStore from './store/householdRegister';
import modifyStore from './store/householdModify';
import residenceStore from './store/residenceRegister';
import immigrationStore from './store/immigrationRegister';

import NotFoundPage from './pages/NotFoudPage';

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');


class App extends Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <div>
                        <Route exact path="/" component={Home} />
                        <Provider store={registerStore}>
                            <Route path="/form/newborn" component={Newborn} />
                            <Route path="/form/household" component={HouseholdRegister} />
                            <Route path="/form/deregister" component={Deregister} />
                        </Provider>
                        <Provider store={modifyStore}>
                            <Route path="/form/household-modify" component={HouseholdModify} />
                        </Provider>
                        <Provider store={residenceStore}>
                            <Route path="/form/residence-register" component={Residence} />
                        </Provider>
                        <Provider store={immigrationStore}>
                            <Route path="/form/immigration-register" component={Immigration} />
                        </Provider>
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
