import * as idNumberUltil from '../ultilities/idNumberUltil';

const defaultStatus = 'process';

const initialState = {
    last: 0,
    current: 0,
    posting: false,
    status: defaultStatus,
    applicant: {}
}

const immigrationRegister = (state = initialState, action) => {
    if (action.type === 'PREVIOUS') {
        return {
            ...state,
            current: state.last,
            last: --state.last,
            status: defaultStatus
        };
    }

    if (action.type ==='SUBMIT_APPLICANT') {
        let applicant = action.applicant;
        applicant.birthDate = idNumberUltil.getBirthDate(applicant.idNumber);

        return {
            ...state,
            applicant: applicant,
            last: state.current,
            current: action.current
        }
    }

    if (action.type === 'SUBMIT_BUSSINESS') {
        let bussiness = action.bussiness;

        return {
            ...state,
            passport: bussiness.passport,
            hk: bussiness.hk,
            tw: bussiness.tw,

            current: state.current + 1,
            last: state.last
        };
    }

    if (action.type === 'SUBMIT_FORM') {
        return {
            ...state,
            posting: true
        };
    }

    if (action.type === 'SUBMIT_FINISH') {
        let status = 'finish'
        let current = state.current + 1;
        let posting = true;
        if (action.error) {
            status = 'error';
            current -= 1;
            posting = false;
        }
        return {
            ...state,
            posting: posting,
            current: current,
            status: status
        }

    }

    return state;
}

export default immigrationRegister;