import * as data from '../const';
import * as idNumberUltil from '../ultilities/idNumberUltil';

const initialState = {
    age: 0,
};

const householdmodify = (state = initialState,action) => {
    switch (action.type) {
        case 'SUBMIT_APPLICANT':
            const { idNumber } = action.applicant;
            let age= idNumberUltil.getAge(idNumber);
            return { ...state, age: age };

        case 'CHANGE_ITEM':
            return { ...state, item: action.item, originalValue: action.originalValue }

        case 'SUBMIT_MODIFY_ITEM':
            return { ...state, modifiedValue: action.modifiedValue, item: action.item }

        case 'SUBMIT_MODIFY_PARENTS':
            return { ...state, mother: action.mother, father: action.father }

        default:
            return state;
    }
}

export const householdModify = householdmodify;