import React from 'react';
import {
    Form, Button, Input,
    Select,
    ConfigProvider,
    DatePicker,
    Alert,
    Radio
} from 'antd';
import { connect } from 'react-redux';

import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';

import MonthInput from './MothInput'
import * as idNumberUltil from '../ultilities/idNumberUltil';

import * as data from '../const';

import * as action from '../actions/residence';

const { Option } = Select;

class ResidenceInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            residenceType:''
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const { validateFieldsAndScroll } = this.props.form;
        const { dispatch, residenceInfo } = this.props;

        validateFieldsAndScroll({
            scroll: { offsetTop: 97 }
        }, (err, values) => {
            if (!err) {
                dispatch(action.submitResidenceInfo({
                    ...residenceInfo,
                    ...values
                }));

                window.scrollTo(0, 0);
            }
        });
    }

    prev() {
        const { dispatch } = this.props;
        dispatch(action.previous());
        window.scrollTo(0, 0);
    }

    selectResidenceType(value) {
        this.setState({
            residenceType:value
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const { residenceInfo } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <Form className="residence-form" onSubmit={e => this.handleSubmit(e)}>
                <Form.Item extra={
                    <Alert message='现居住地址应填写办理居住证所住处所地址'
                        style={{ textAlign: "left" }}
                        type='info' closable
                    />}
                    {...formItemLayout}
                    label='现居住地址'
                >
                    {getFieldDecorator('address', {
                        rules: [{ required: true, message: '请填写现居住地址' }],
                        initialValue: residenceInfo.address
                    })(
                        <Input placeholder='现居住地址' />
                    )}
                </Form.Item>
                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='与房主关系'*/}
                {/*>*/}
                {/*    {getFieldDecorator('relationship', {*/}
                {/*        rules: [{ required: true,message:'请选择与房主关系' }],*/}
                {/*        initialValue: residenceInfo.relationship*/}
                {/*    })(*/}
                {/*        <Select placeholder='请选择与房主关系'>*/}
                {/*        {*/}
                {/*            data.residenceRelationshipData.map(relation => <Option key={relation}>{relation}</Option>)*/}
                {/*        }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}

                <Form.Item
                    {...formItemLayout}
                    label='居住处所'
                >
                    {getFieldDecorator('residenceType', {
                        rules: [{ required: true, message: '请选择居住处所类别' }],
                        initialValue: residenceInfo.residenceType
                    })(
                        <Select placeholder='请选择居住处所类别' onChange={e => this.selectResidenceType(e)}>
                            {
                                Array.from(data.residenceType).map(([key]) => <Option key={key}>{key}</Option>)
                            }
                        </Select>
                    )}
                </Form.Item>

                {
                    (this.state.residenceType === '居民家中' || this.state.residenceType === '租赁房屋') &&
                    <Form.Item
                        {...formItemLayout}
                        label='房主姓名'
                    >
                        {getFieldDecorator('ownerName', {
                            rules: [{ required: true, message: '请填写房主姓名' }],
                            initialValue: residenceInfo.ownerName
                        })(
                            <Input placeholder='房主姓名' />
                        )}
                    </Form.Item>
                }

                {
                    (this.state.residenceType === '居民家中' || this.state.residenceType === '租赁房屋') &&
                    <Form.Item
                        {...formItemLayout}
                        label='房主电话号码'
                    >
                        {getFieldDecorator('ownerPhone', {
                            //rules: [{
                            //    validator: (rule, value, callback) => {
                            //        let errors = [];
                            //        if (value && value.length > 0) {
                            //            let validate = idNumberUltil.validator(value);
                            //            if (!validate.validated) {
                            //                errors.push(new Error('身份证号码格式不正确'));
                            //            }
                            //        }

                            //        callback(errors);
                            //    }
                            //}],
                            rules: [{ required: true, message: '请填写房主电话号码' }],
                            initialValue: residenceInfo.ownerIDNumber
                        })(
                            <Input placeholder='房主电话号码' />
                        )}
                    </Form.Item>}

                <Form.Item
                    {...formItemLayout}
                    label='居住事由'
                >
                    {getFieldDecorator('reason', {
                        rules: [{ required: true, message: '请选择居住事由' }],
                        initialValue: residenceInfo.reason
                    })(
                        <Select placeholder='请选择居住事由'>
                        {
                            //data.residenceReasonData.map(relation => <Option key={relation}>{relation}</Option>)
                            Array.from(data.residenceReasonData).map(([key]) => <Option key={key}>{key}</Option>)
                        }
                        </Select>
                    )}
                </Form.Item>

                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='居住或从业'*/}
                {/*>*/}
                {/*    {getFieldDecorator('status', {*/}
                {/*        rules: [{ required: true, message: '请选择居住或从业状态' }],*/}
                {/*        initialValue: residenceInfo.status*/}
                {/*    })(*/}
                {/*        <Select placeholder='请选择居住或从业状态'>*/}
                {/*        {*/}
                {/*            data.residenceStatusData.map(relation => <Option key={relation}>{relation}</Option>)*/}
                {/*        }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}


                <Form.Item
                    {...formItemLayoutWithOutLabel}
                >
                    <Button onClick={() => this.prev()} style={{ float: "left" }}>上一步</Button>
                    <Button htmlType="submit" type='primary' style={{ float: "right" }}>下一步</Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state
    };
};

let Residence = Form.create({ name: 'residence_residence_info' })(ResidenceInfo);
export default connect(mapStateToProps)(Residence);