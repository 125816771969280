import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { connect } from 'react-redux'

import * as action from '../actions/householdmodify';

class ParentsForm extends React.Component {
    handleSubmit(e) {
        e.preventDefault();

        const { validateFields } = this.props.form;
        const { dispatch } = this.props;

        validateFields((err, values) => {
            if (!err) {
                dispatch(action.submitParents(values.father, values.mother));
                window.scrollTo(0, 0);
            }
        });
    }

    prev() {
        const { dispatch } = this.props;

        dispatch(action.previous);
    }

    render() {
        const { father, mother } = this.props;
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <div>
                <Alert message='未满18周岁公民申请变更更正户口项目需父母双方签字同意'
                    style={{ marginBottom:10 }}
                />
                <Form className="modify-parents-form" onSubmit={e => this.handleSubmit(e)}>
                    <Form.Item
                        {...formItemLayout}
                        label='申请人父亲姓名'
                    >
                        {getFieldDecorator('father', {
                            rules: [{ required: true, message: '请填写申请人父亲姓名。' }],
                            initialValue: father
                        })(
                            <Input placeholder='申请人父亲姓名' />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label='申请人母亲姓名'
                    >
                        {getFieldDecorator('mother', {
                            rules: [{ required: true, message: '请填写申请人母亲姓名。' }],
                            initialValue: mother
                        })(
                            <Input placeholder='申请人母亲姓名' />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button onClick={() => this.prev()}>上一步</Button>
                        <Button type="primary" htmlType="submit" style={{ float: "right" }}>下一步</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.householdModify,
        ...state.householdForm
    };
};

let HouseholdModifyParentsForm = Form.create({ name: 'household_item_modify_parent' })(ParentsForm);
HouseholdModifyParentsForm = connect(mapStateToProps)(HouseholdModifyParentsForm);

export default HouseholdModifyParentsForm;