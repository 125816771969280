import React from 'react';
import { Input, Form, Button, Select, Cascader, ConfigProvider, Alert } from 'antd';
import { connect } from 'react-redux';

import * as idNumberUltil from '../ultilities/idNumberUltil';

import * as data from '../const';
import areaData from '../districAreaData';

const { Option } = Select;


class ImmigrationApplicant extends React.Component {
    handleSubmit = e => {
        e.preventDefault();

        const { validateFields } = this.props.form;
        const { dispatch } = this.props;

        validateFields((err, values) => {
            if (!err) {
                values.idNumber = values.idNumber.toUpperCase();
                dispatch({
                    type: 'SUBMIT_APPLICANT',
                    current: 1,
                    applicant: values
                });
                window.scrollTo(0, 0);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { applicant } = this.props;


        const areafilter = (inputValue, path) => {
            return (path.some(option => option.label.indexOf(inputValue) > -1));
        }


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <Form className="newborn-form" onSubmit={this.handleSubmit}>
                <Form.Item
                    {...formItemLayout}
                    label="申请人姓名"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请填写申请人姓名。' }],
                        initialValue: applicant.name
                    })(
                        <Input placeholder="申请人姓名" />
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label="拼音姓"
                >
                    {getFieldDecorator('lastname', {
                        rules: [{ required: true, message: '请填写姓氏拼音。' }],
                        initialValue: applicant.name
                    })(
                        <Input placeholder="姓氏拼音" />
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label="拼音名"
                >
                    {getFieldDecorator('firstname', {
                        rules: [{ required: true, message: '请填写拼音名。' }],
                        initialValue: applicant.name
                    })(
                        <Input placeholder="拼音名" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="民族"
                >
                    {getFieldDecorator('nation', {
                        rules: [{ required: true, message: '请选择民族。' }],
                        initialValue: applicant.name
                    })(
                        <Select placeholder="请选择民族">
                        {
                            data.nationData.map(nation => <Option key={nation}>{nation}</Option>)
                        }
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label="申请人性别"
                >
                    {getFieldDecorator('gender', {
                        rules: [{ required: true, message: '请选择申请人性别。' }],
                        initialValue: applicant.gender
                    })(
                        <Select placeholder="请选择申请人性别">
                            {
                                data.genderData.map(gender => <Option key={gender}>{gender}</Option>)
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="申请人身份证号码"
                >
                    {getFieldDecorator('idNumber', {
                        rules: [{
                            required: true, message: '身份证号码必填'
                        }, {
                            validator: (rule, value, callback) => {
                                const errors = [];

                                if (value && value.length > 0) {
                                    let validate = idNumberUltil.validator(value);
                                    if (!validate.validated) {
                                        errors.push(new Error('身份证号码格式不正确'));
                                    }
                                }
                                callback(errors.slice(0, 1));
                            }
                        }],
                        initialValue: applicant.idNumber
                    })(
                        <Input placeholder="申请人身份证号码" />
                    )}
                </Form.Item>
                <ConfigProvider
                    renderEmpty={() => (
                        <div style={{ textAlign: 'center' }}>
                            未找到对应选项
                        </div>
                    )}
                >

                    <Form.Item extra={
                        <Alert message='出生地可填写行政区划名称进行搜索，如：都江堰。'
                            style={{ textAlign: "left" }}
                            type='info' closable
                        />}
                        {...formItemLayout}
                        label='出生地'
                        style={{ clear: "both" }}
                    >
                        {getFieldDecorator('registryArea', {
                            rules: [{ required: true, message: '请填选出生地' }],
                            initialValue: applicant.registryArea
                        })(
                            <Cascader
                                placeholder="请填选出生地。"
                                options={areaData}
                                expandTrigger="hover"
                                showSearch={{ areafilter }}
                            />
                        )}
                    </Form.Item>
                </ConfigProvider>

                <Form.Item
                    {...formItemLayout}
                    label="申请人户口所在地"
                >
                    {getFieldDecorator('address', {
                        rules: [{ required: true, message: '请填写申请人申请人户口所在地。' }],
                        initialValue: applicant.address
                    })(
                        <Input placeholder="申请人户口首页地址" />
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label='本人联系电话'
                >
                    {getFieldDecorator('phone', {
                        rules: [{ required: true, message: '请填写申请人联系电话' }],
                        initialValue:applicant.phone
                    })(
                        <Input placeholder='本人联系电话'/>
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label='紧急联系人姓名'
                >
                    {getFieldDecorator('emergencyName', {
                        rules: [{ required: true, message: '请填写紧急联系人姓名' }],
                        initialValue: applicant.phone
                    })(
                        <Input placeholder='紧急联系人姓名' />
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label='紧急联系人电话'
                >
                    {getFieldDecorator('emergencyPhone', {
                        rules: [{ required: true, message: '请填写紧急联系人电话' }],
                        initialValue: applicant.phone
                    })(
                        <Input placeholder='紧急联系人电话' />
                    )}
                </Form.Item>

                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>下一步</Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return { ...state };
}

let Applicant = Form.create({ name: 'immigration_applicant' })(ImmigrationApplicant);
Applicant = connect(mapStateToProps)(Applicant);

export default Applicant;