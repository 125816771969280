import registerStore from '../store/householdRegister';
import modifyStore from '../store/householdModify';
import residenceStore from '../store/residenceRegister'

const stores = [
    registerStore,
    modifyStore,
    residenceStore
];

export const resetState = () => {
    stores.map(store => store.dispatch({ type: 'RESET_FORM' }));
};