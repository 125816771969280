import { combineReducers, createStore } from 'redux';

import { householdRegister } from '../reducers/householdregister';
import { householdForm } from '../reducers/householdform';

const registerReducer = combineReducers({
    householdRegister,
    householdForm
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_FORM') {
        state = undefined
    }

    return registerReducer(state, action)
}

const store = createStore(rootReducer);
export default store;