let memberid = 1;

export const changeGender = (value) => {
    return {
        type: 'CHANGE_GENDER',
        gender: value
    };
}

export const submitFinish = (error) => {
    return {
        type: 'SUBMIT_FINISH',
        current:3,
        error: error
    }
}

export const submitForm = () => {
    return {
        type: 'SUBMIT_FORM'
    }
}

export const previous =()=> {
    return {
        type: 'PREVIOUS',
    }
};

export const add = (member) => {
    member.id = memberid++;
    return {
        type: 'ADD_MEMBER',
        member
    }
}

export const remove = (id) => {
    return {
        type: 'REMOVE_MEMBER',
        id: id
    }
}

export const submitMembers =()=> {
    return {
        type: 'SUBMIT_MEMBERS',
        current: 2
    }
}