import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Form, message } from 'antd';
import axios from 'axios';

import * as data from '../const';
import * as api from '../api';

class ImmigrationConfirm extends React.Component{
    async submit() {
        const { applicant,
            passport, hk, tw,
            dispatch
        } = this.props;

        let passData=99, hkData=99, twData=99;
        if (passport) {
            passData = data.immigrationPassportData.findIndex(item => item === passport);
        }

        if (hk) {
            hkData = data.immigrationHK.findIndex(item => item === hk);
        }

        if (tw) {
            twData = data.immigrationTW.findIndex(item => item === tw);
        }

        dispatch({ type: 'SUBMIT_FROM' });
        let loading = message.loading('正在提交资料...', 5);
        try {

            let response = await axios.post(api.immigration.apply, {
                ...applicant,
                area: applicant.registryArea.join(' '),
                tw: twData,
                hk: hkData,
                passport: passData
            });

            if (response.data.success) {
                dispatch({
                    type: 'SUBMIT_FINISH',
                    error: false
                });
            }
            else {
                dispatch({
                    type: 'SUBMIT_FINISH',
                    error: true
                });
                message.error(response.data.message);
            }
        } catch (e) {
            dispatch({
                type: 'SUBMIT_FINISH',
                error: true
            });
            message.error(e.message, 5);
        }
        finally {
            loading();
        }
    }

    prev() {
        const { dispatch } = this.props;
        dispatch({
            type: 'PREVIOUS'
        });
        window.scrollTo(0, 0);
    }

    render() {
        const { applicant, passport, hk, tw, posting } = this.props;
        
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }

        return (
            <div>
                <Card title='基本信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                >
                    <Form.Item label='姓名'
                        {...formItemLayout}
                    >
                        <label>{applicant.name}</label>
                    </Form.Item>
                    <Form.Item label='拼音姓'
                        {...formItemLayout}
                    >
                        <label>{applicant.lastname}</label>
                    </Form.Item>
                    <Form.Item label='拼音名'
                        {...formItemLayout}
                    >
                        <label>{applicant.firstname}</label>
                    </Form.Item>

                    <Form.Item label='性别'
                        {...formItemLayout}
                    >
                        <label>{applicant.gender}</label>
                    </Form.Item>
                    <Form.Item label='民族'
                        {...formItemLayout}
                    >
                        <label>{applicant.nation}</label>
                    </Form.Item>
                    <Form.Item label='身份证号码'
                        {...formItemLayout}
                    >
                        <label>{applicant.idNumber}</label>
                    </Form.Item>
                    <Form.Item label='出生日期'
                        {...formItemLayout}
                    >
                        <label>{applicant.birthDate}</label>
                    </Form.Item>
                    <Form.Item label='出生地'
                        {...formItemLayout}
                    >
                        <label>{applicant.registryArea.join()}</label>
                    </Form.Item>
                    <Form.Item label='户口所在地'
                        {...formItemLayout}
                    >
                        <label>{applicant.address}</label>
                    </Form.Item>
                    <Form.Item label='本人电话'
                        {...formItemLayout}
                    >
                        <label>{applicant.phone}</label>
                    </Form.Item>
                    <Form.Item label='紧急联系人'
                        {...formItemLayout}
                    >
                        <label>{applicant.emergencyName}/{applicant.emergencyPhone}</label>
                    </Form.Item>
                </Card>

                <Card title='业务办理信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                >
                    <Form.Item label='普通护照'
                        {...formItemLayout}
                    >
                        <label>{passport ? passport : '暂不办理'}</label>
                    </Form.Item>

                    <Form.Item label='港澳通行证'
                        {...formItemLayout}
                    >
                        <label>{hk ? hk: '暂不办理'}</label>
                    </Form.Item>

                    <Form.Item label='台湾通行证'
                        {...formItemLayout}
                    >
                        <label>{tw ?tw:'暂不办理'}</label>
                    </Form.Item>
                </Card>

                <Button onClick={() => this.prev()} style={{ float: "left" }}>上一步</Button>
                <Button onClick={() => this.submit()} type='primary' style={{ float: "right" }}
                    disabled={posting}
                >提交</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(ImmigrationConfirm);