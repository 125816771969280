import React from 'react';
import { Button, Col, Row, Icon } from 'antd';
import { Link } from 'react-router-dom';
import Layout from '../layouts/DefaultLayout';

class NotFoundPage extends React.Component {
    render() {
        return (
            <Layout title='页面不存在'>
                <div style={{ maxWidth: "480px", padding: "5px", margin: "0 auto" }}>
                    <Row>
                        <Col span={4}>
                            <Icon type="close-circle"
                                theme="twoTone" twoToneColor="#f00"
                                style={{ fontSize: 60, marginTop: 45, verticalAlign: 'middle' }}
                            />
                        </Col>
                        <Col span={20}>
                            <div>
                                <h3 style={{ fontWeight: "bold" }}>页面不存在</h3>
                                <div>你所访问的页面不存在，请返回首页</div>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div style={{ clear: 'both', textAlign: "center" }}>
                    <Link to='/'><Button>返回</Button></Link>
                </div>
            </Layout>
        );
    }
}

export default NotFoundPage;