export const changeItem = (item, originalValue) => {
    return {
        type: 'CHANGE_ITEM',
        originalValue: originalValue,
        item: item
    }
}

export const previous = {
    type: 'PREVIOUS',
}

export const submitItem = (item,value,next) => {
    return {
        type: 'SUBMIT_MODIFY_ITEM',
        modifiedValue: value,
        item:item,
        next: next
    }
}

export const submitParents = (father, mother) => {
    return {
        type: 'SUBMIT_MODIFY_PARENTS',
        father: father,
        mother: mother,
    }
}

export const submitFinish = (error) => {
    return {
        type: 'SUBMIT_FINISH',
        current: 4,
        error: error
    }
}

export const submitForm = () => {
    return {
        type: 'SUBMIT_FORM'
    }
}