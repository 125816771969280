import React from 'react';
import { Button, Row, Col, Icon } from 'antd';
import { Link } from 'react-router-dom';

class SuccessPage extends React.Component {
    render() {
        return (
            <div>
                <div style={{ marginBottom: 35, marginTop: 15, lineHeight: 2, maxWidth: 550 }}>
                    <Row>
                        <Col span={8}>
                            <Icon type="check-circle"
                                theme="twoTone" twoToneColor="#52c41a"
                                style={{ fontSize: 60, marginTop: 45, verticalAlign: 'middle' }}
                            />
                        </Col>
                        <Col span={16}>
                            <div>
                                <h3 style={{ fontWeight: "bold" }}>资料提交成功</h3>
                                <div>本次提交资料当日业务办理有效</div>
                                <div>请携带申请人身份证到当地办理机关办理相关业务</div>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div style={{ clear: 'both', textAlign: "center" }}>
                    <Link to='/'><Button>返回</Button></Link>
                </div>
            </div>
        )
    }
}

export default SuccessPage;