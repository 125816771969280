import React, { Component } from 'react';
import { Layout } from 'antd';

const {
    Header, Footer, Content,
} = Layout;

class HomeLayout extends React.Component {
    render() {
        const { title, children } = this.props;

        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Header>
                    <div className="title">{title}</div>
                </Header>
                <Content>
                    <div style={{ paddingLeft: 24, paddingRight: 24, maxWidth: 720, margin:'0 auto' }}>{children}</div>
                </Content>
            </Layout>
        );
    }
}

export default HomeLayout;