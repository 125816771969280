import ResidenceInfo from "../components/ResidenceInfo";

export const previous = () => {
    return { type: 'PREVIOUS' };
}

export const submitBasicInfo = basicInfo => {
    return {
        type: 'SUBMIT_BASIC_INFO',
        basicInfo: basicInfo
    }
}

export const submitResidenceInfo = residenceInfo => {
    return {
        type: 'SUBMIT_RESIDENCE_INFO',
        residenceInfo: residenceInfo
    }
}

export const submitForm = () => {
    return { type:'SUBMIT_FROM'}
}

export const submitFinish = (error) => {
    return {
        type: 'SUBMIT_FINISH',
        error: error
    }
}