const householdTypesData = [
    { value: '家庭', text: '家庭' },
    { value: '集体', text: '集体' },
];

export const houseHoldTypes = householdTypesData

export const genderData = ['男', '女'];

export const modifyItemData = [{
    item: '姓名变更',
    applicantProperty: 'name'
}];

export const nationData = [
    '汉族', '仫佬族', '黎族', '土家族',
    '蒙古族', '羌族', '僳僳族', '哈尼族',
    '回族', '布朗族', '佤族', '哈萨克族',
    '藏族', '撒拉族', '畲族', '傣族',
    '维吾尔族', '毛南族', '高山族', '德昂族',
    '苗族', '仡佬族', '拉祜族', '保安族',
    '彝族', '锡伯族', '水族', '裕固族',
    '壮族', '阿昌族', '东乡族', '京族',
    '布依族', '普米族', '纳西族', '独龙族',
    '朝鲜族', '塔吉克族', '景颇族', '鄂伦春族',
    '满族', '怒族', '柯尔克孜族', '赫哲族',
    '侗族', '乌孜别克族', '土族', '门巴族',
    '瑶族', '俄罗斯族', '达斡尔族', '珞巴族',
    '白族', '鄂温克族', '塔塔尔族', '基诺族'
]


export const marriageData = [
    '未婚', '已婚', '丧偶', '离婚', '其它',
];

export const politicalData = [
    '党员', '团员', '民主党派', '群众', '其他'
]

export const residenceHouseholdTypeData = [
    '居民家庭', '农业家庭',
    '居民集体', '农业集体',
    '其他'
];

export const residenceRegistryTypeData = [
    '省内城镇', '省内乡村',
    '省外城镇', '省外乡村',
    '其他'
]

export const residenceEducationData = [
    '研究生', '大学本科', '大学专科',
    '中专', '高中', '初中',
    '小学', '文盲'
]

export const residenceRelationshipData = [
    '本人', '租方', '公司',
    '父母', '子女', '夫妻',
    '祖父母', '外祖父母',
    '孙子女', '外孙子女'
];

//export const residenceType = [
//    '旅店', '居民家中',
//    '单位内部', '工地现场',
//    '租赁房屋', '自购房',
//    '其它'
//]
export const residenceType = new Map([
    ['居民家中', 100],
    ['单位内部', 101],
    ['工地现场', 102],
    ['租赁房屋', 103],
    ['自购房', 104],
    ['小区门卫', 105],
    ['营业房', 106],
    ['宗教场所', 107],
    ['其它', 108]
]);

//export const residenceReasonData = [
//    '务工', '务农', '经商', '服务',
//    '因公出差', '借读培训', '治病疗养',
//    '保姆', '投亲靠友', '探亲访友', '旅游观光',
//    '其它'
//]
export const residenceReasonData = new Map([
    ['务工', 100],
    ['务农', 101],
    ['经商', 102],
    ['服务', 103],
    ['因公出差', 104],
    ['借读培训', 105],
    ['治病疗养', 106],
    ['保姆', 107],
    ['投亲靠友', 108],
    ['探亲访友', 109],
    ['其它', 110]
]);

export const residenceStatusData = [
    '本址居住', '本址从业', '本址居住并从业'
]

//export const residenceApplyTypeData = [
//    '合法稳定就业', '合法稳定住所',
//    '连续就读', '符合人才落户条件',
//    '其它'
//]

export const residenceApplyTypeData = new Map([
    ['合法稳定就业', 10],
    ['合法稳定住所', 11],
    ['连续就读', 12],
    ['共同生活的直属亲属具有本市户籍', 13],
    ['符合人才落户条件(办证中心签字、户口专用章)', 14]
]);

export const immigrationPassportData = [
    '首次申领'
]

export const immigrationHK = [
    '首次申领'
]

export const immigrationTW = [
    '首次申领'
]