import React, { Component } from 'react';
import { Form, Input, Select, Button, Alert } from 'antd'
import { connect } from 'react-redux'

import * as idNumberUltil from '../ultilities/idNumberUltil';

import * as data from '../const';

const { Option } = Select;

class HouseholdApplicant extends React.Component {
    handleSubmit = e => {
        e.preventDefault();

        const { validateFields } = this.props.form;
        const { dispatch } = this.props;

        validateFields((err, values) => {
            if (!err) {
                values.idNumber = values.idNumber.toUpperCase();
                dispatch({
                    type: 'SUBMIT_APPLICANT',
                    current: 1,
                    applicant: values
                });
                window.scrollTo(0, 0);
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { applicant,formType } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <Form className="newborn-form" onSubmit={this.handleSubmit}>
                {
                    formType === 'Newborn'
                    && <Alert style={{ marginTop: 15, marginBottom: 10, maxWidth: 720 }} message='申请人信息应填写新生儿父方或母方户口信息，新生儿将入户到所填写监护人户口中' />
                }

                {
                    formType === 'Modify'
                    && <Alert style={{ marginTop: 15, marginBottom: 10, maxWidth: 720 }} message='申请人信息应填写需要进行户口项目修改的个人姓名' />
                }
                <Form.Item
                    {...formItemLayout}
                    label="申请人姓名"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请填写申请人姓名。' }],
                        initialValue: applicant.name
                    })(
                        <Input placeholder="申请人姓名" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="申请人性别"
                >
                    {getFieldDecorator('gender', {
                        rules: [{ required: true, message: '请选择申请人性别。' }],
                        initialValue: applicant.gender
                    })(
                        <Select placeholder="请选择申请人性别">
                            {
                                data.genderData.map(gender => <Option key={gender}>{gender}</Option>)
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="申请人身份证号码"
                >
                    {getFieldDecorator('idNumber', {
                        rules: [{
                            required: true,message:'身份证号码必填'
                        }, {
                            validator: (rule, value, callback) => {
                                const errors = [];
                                
                                if (value && value.length > 0) {
                                    let validate = idNumberUltil.validator(value);
                                    if (!validate.validated) {
                                        errors.push(new Error('身份证号码格式不正确'));
                                    }
                                }
                                callback(errors.slice(0, 1));
                            }
                        }],
                        initialValue: applicant.idNumber
                    })(
                        <Input placeholder="申请人身份证号码" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="申请人户口所在地"
                >
                    {getFieldDecorator('address', {
                        rules: [{ required: true, message: '请填写申请人申请人户口所在地。' }],
                        initialValue: applicant.address
                    })(
                        <Input placeholder="申请人户口首页地址" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="申请人户口性质"
                >
                    {getFieldDecorator('type', {
                        rules: [{ required: true, message: '请选择申请人户口性质。' }],
                        initialValue: applicant.type
                    })(
                        <Select placeholder="请选择申请人户口性质">
                            {
                                data.houseHoldTypes.map(type => <Option key={type.value}>{type.text}</Option>)
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>下一步</Button>
                </Form.Item>
            </Form>
        );
    }
};

const mapStateToProps = state => {
    return {
        ...state.householdForm
    };
};

let Applicant = Form.create({ name: 'household_applicant' })(HouseholdApplicant);
Applicant = connect(mapStateToProps)(Applicant);

export default Applicant;