import { combineReducers, createStore } from 'redux';

import { householdModify } from '../reducers/householdmodify';
import { householdForm } from '../reducers/householdform';

const registerReducer = combineReducers({
    householdModify,
    householdForm
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_FORM') {
        state = undefined
    }

    return registerReducer(state, action)
}

const store = createStore(rootReducer);
export default store;