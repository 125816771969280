import React from 'react';
import {
    Form, Button, Input,
    Select, Cascader,
    ConfigProvider,
    Alert
} from 'antd';
import { connect } from 'react-redux'

import * as idNumberUltil from '../ultilities/idNumberUltil';

import * as data from '../const';
import areaData from '../districAreaData';

import * as action from '../actions/residence';

const { Option } = Select;

class ResidenceApplicant extends React.Component {
    handleSubmit = e => {
        e.preventDefault();

        const { validateFieldsAndScroll } = this.props.form;
        const { dispatch } = this.props;

        validateFieldsAndScroll({
            scroll: { offsetTop: 97 }
        }, (err, values) => {
            if (!err) {
                dispatch(action.submitBasicInfo(values));
                window.scrollTo(0, 0);
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { basicInfo } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        const areafilter = (inputValue, path) => {
            return (path.some(option => option.label.indexOf(inputValue) > -1));
        }

        return (
            <Form className="residence-form" onSubmit={this.handleSubmit}>
                <Form.Item
                    {...formItemLayout}
                    label='申办类型'
                >
                    {getFieldDecorator('residenceApplyType', {
                        rules: [{ required: true, message: '请选择申办类型' }],
                        initialValue: basicInfo.residenceApplyType
                    })(
                        <Select placeholder="申办类型">
                            {
                                Array.from(data.residenceApplyTypeData).map(([key]) => <Option key={key}>{key}</Option>)
                            }
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label='姓名'
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请填写姓名' }],
                        initialValue: basicInfo.name
                    })(
                        <Input placeholder='姓名' />
                    )}
                </Form.Item>
                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label="性别"*/}
                {/*>*/}
                {/*    {getFieldDecorator('gender', {*/}
                {/*        rules: [{ required: true, message: '请选择性别。' }],*/}
                {/*        initialValue: basicInfo.gender*/}
                {/*    })(*/}
                {/*        <Select placeholder="请选择性别">*/}
                {/*            {*/}
                {/*                data.genderData.map(gender => <Option key={gender}>{gender}</Option>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}
                {/*<ConfigProvider*/}
                {/*    renderEmpty={() => (*/}
                {/*        <div style={{ textAlign: 'center' }}>未找到对应选项</div>*/}
                {/*    )}*/}
                {/*>*/}
                {/*    <Form.Item extra={*/}
                {/*        <Alert message='民族可填写进行搜索，如：汉。'*/}
                {/*            style={{ textAlign: "left" }}*/}
                {/*            type='info' closable*/}
                {/*        />}*/}
                {/*        {...formItemLayout}*/}
                {/*        label='民族'*/}
                {/*    >*/}
                {/*        {getFieldDecorator('nation', {*/}
                {/*            rules: [{ required: true, message: '请填选民族' }],*/}
                {/*            initialValue: basicInfo.nation*/}
                {/*        })(*/}
                {/*            <Select*/}
                {/*                placeholder='请填选民族'*/}
                {/*                showSearch*/}
                {/*                filterOption={(input, option) => option.props.children.indexOf(input) >= 0}*/}
                {/*            >*/}
                {/*                {*/}
                {/*                    data.nationData.map(nation => <Option key={nation} value={nation}>{nation}</Option>)*/}
                {/*                }*/}
                {/*            </Select>*/}
                {/*        )}*/}
                {/*    </Form.Item>*/}
                {/*</ConfigProvider>*/}
                <Form.Item
                    {...formItemLayout}
                    label="身份证号码"
                >
                    {getFieldDecorator('idNumber', {
                        rules: [{ required: true, message: '身份证号码必填。' }, {
                            validator: (rule, value, callback) => {
                                const errors = [];

                                if (value && value.length > 0) {
                                    let validate = idNumberUltil.validator(value);
                                    if (!validate.validated) {
                                        errors.push(new Error('身份证号码格式不正确'));
                                    }
                                }
                                callback(errors.slice(0, 1));
                            }
                        }],
                        initialValue: basicInfo.idNumber
                    })(
                        <Input placeholder="身份证号码" />
                    )}
                </Form.Item>
                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='婚姻状况'*/}
                {/*>*/}
                {/*    {getFieldDecorator('marriage', {*/}
                {/*        rules: [{ required: true, message: '请选择婚姻状况' }],*/}
                {/*        initialValue: basicInfo.marriage*/}
                {/*    })(*/}
                {/*        <Select placeholder='婚姻状况'>*/}
                {/*            {*/}
                {/*                data.marriageData.map(status => <Option key={status}>{status}</Option>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='政治面貌'*/}
                {/*>*/}
                {/*    {getFieldDecorator('political', {*/}
                {/*        rules: [{ required: true, message: '请选择政治面貌' }],*/}
                {/*        initialValue: basicInfo.political*/}
                {/*    })(*/}
                {/*        <Select placeholder='请选择政治面貌'>*/}
                {/*            {*/}
                {/*                data.politicalData.map(status => <Option key={status}>{status}</Option>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}

                <Form.Item
                    {...formItemLayout}
                    label='手机号码'
                >
                    {getFieldDecorator('phone', {
                        rules: [{ required: true, message: '请填写手机号码。' }],
                        initialValue: basicInfo.phone
                    })(
                        <Input placeholder='手机号码' />
                    )}
                </Form.Item>

                <ConfigProvider
                    renderEmpty={() => (
                        <div style={{ textAlign: 'center' }}>
                            未找到对应选项
                        </div>
                    )}
                >

                    <Form.Item extra={
                        <Alert message='户籍所在地可填写行政区划名称进行搜索，如：都江堰。'
                            style={{ textAlign: "left" }}
                            type='info' closable
                        />}
                        {...formItemLayout}
                        label='户籍所在地'
                        style={{ clear: "both" }}
                    >
                        {getFieldDecorator('registryArea', {
                            rules: [{ required: true, message: '请填选户籍所在地' }],
                            initialValue: basicInfo.registryArea
                        })(
                            <Cascader
                                placeholder="请填选户籍所在地。"
                                options={areaData}
                                expandTrigger="hover"
                                showSearch={{ areafilter }}
                            />
                        )}
                    </Form.Item>
                </ConfigProvider>

                <Form.Item
                    {...formItemLayout}
                    label='户籍详细地址'
                >
                    {getFieldDecorator('registryAddress', {
                        rules: [{ required: true, message: '请选择户籍详细地址' }],
                        initialValue: basicInfo.registryAddress
                    })(
                        <Input placeholder='请填写户籍详细地址' />
                    )}
                </Form.Item>

                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='户口类别'*/}
                {/*>*/}
                {/*    {getFieldDecorator('householdType', {*/}
                {/*        rules: [{ required: true, message: '请选择户口类别' }],*/}
                {/*        initialValue: basicInfo.householdType*/}
                {/*    })(*/}
                {/*        <Select placeholder='请选择户口类别'>*/}
                {/*            {*/}
                {/*                data.residenceHouseholdTypeData.map(status => <Option key={status}>{status}</Option>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}

                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='户籍地类别'*/}
                {/*>*/}
                {/*    {getFieldDecorator('registryType', {*/}
                {/*        initialValue: basicInfo.registryType*/}
                {/*    })(*/}
                {/*        <Select placeholder='请选择户籍地类别' allowClear>*/}
                {/*            {*/}
                {/*                data.residenceRegistryTypeData.map(status => <Option key={status}>{status}</Option>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}

                {/*<Form.Item*/}
                {/*    {...formItemLayout}*/}
                {/*    label='文化程度'*/}
                {/*>*/}
                {/*    {getFieldDecorator('education', {*/}
                {/*        rules: [{ required: true, message: '请选择文化程度' }],*/}
                {/*        initialValue: basicInfo.education*/}
                {/*    })(*/}
                {/*        <Select placeholder='请选文化程度'>*/}
                {/*            {*/}
                {/*                data.residenceEducationData.map(status => <Option key={status}>{status}</Option>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}

                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>下一步</Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state
    };
};

let Applicant = Form.create({ name: 'residence_basic_info' })(ResidenceApplicant);
export default connect(mapStateToProps)(Applicant);