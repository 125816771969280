import React from 'react';
import { Input } from 'antd';

class MonthInput extends React.Component {
    onChange = (e) => {
        const { value } = e.target;
        const reg = /^(0|[1-9]|[1][0-2])$/;
        if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.props.onChange(value);
        }
    }
    
    render() {
        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                maxLength={2}
            />
        );
    }
}

export default MonthInput;