import React, { Component } from 'react';
import { Button, Card, Collapse, Form, message } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';

import * as api from '../api';

import * as action from '../actions/household'
import { error } from 'util';

const Panel = Collapse.Panel;

class HouseholdConfirm extends React.Component {
    previous() {
        const { dispatch } = this.props;
        dispatch(action.previous());
        window.scrollTo(0, 0);
    }

    async submit() {
        const { dispatch, applicant, members, formType } = this.props;
        dispatch(action.submitForm());
        let reason = '全域迁移';

        if (formType === 'Newborn') {
            reason = '新生儿入户'
        }

        if (formType === 'Deregister') {
            reason = '';
        }

        let loading = message.loading('正在提交资料...', 5);
        try {
            let url = api.household.register;
            if (formType === 'Deregister') {
                url = api.household.deregister
            }
            let response = await axios(url, {
                method: 'POST',
                data: {
                    ...applicant,
                    members: members,
                    reason: reason
                }
            });

            if (response.data.success) {
                dispatch(action.submitFinish(false));
            }
            else {
                dispatch(action.submitFinish(true));
                message.error(response.data.message);
            }
        } catch (e) {
            dispatch(action.submitFinish(true));
            message.error(e.message,5);
        }
        finally {
            loading();
        }
    }

    render() {
        const { applicant, members, formType, posting } = this.props

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        const customPanelStyle = {
            background: '#f7f7f7',
            borderRadius: 4,
            marginBottom: 24,
            border: 0,
            overflow: 'hidden',
            textAlign: 'left'
        };

        return (
            <div>
                <Card title='申请人信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop:15, marginBottom: 15 }}
            >
                    <p>申请人姓名：{applicant.name}</p>
                    <p>性别：{applicant.gender}</p>
                    <p>身份证号码：{applicant.idNumber}</p>
                    <p>户口所在地：{applicant.address}</p>
                    <p>户口性质：{applicant.type}</p>
                </Card>

                <Card
                    title={formType === 'Newborn' ? '新生儿信息' : '被申请人信息'}
                    style={{ maxWidth: 720, textAlign: "left", marginBottom: 15 }}
                >
                    <Collapse
                        bordered={false}
                        style={{ maxWidth: 720 }}
                    >
                        {members.map(member => {
                            return <Panel header={member.name}
                                style={customPanelStyle}
                                key={member.id}
                            >
                                <Form.Item
                                    {...formItemLayout}
                                    label='姓名'
                                >
                                    <label>{member.name}</label>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label='性别'
                                >
                                    <label>{member.gender}</label>
                                </Form.Item>
                                
                                {
                                    // 2019-03-26 新生儿不填写出生日期 by lujj
                                    formType !== 'Newborn' &&
                                    <Form.Item
                                        {...formItemLayout}
                                        label='身份证号码'
                                    >
                                        <label>{member.idNumber}</label>
                                    </Form.Item>

                                    //<Form.Item
                                    //    {...formItemLayout}
                                    //    label={formType === 'Newborn' ? '出生日期' : '身份证号码'}
                                    //>
                                    //    <label>{member.idNumber}</label>
                                    //</Form.Item>
                                }
                                <Form.Item
                                    {...formItemLayout}
                                    label='关系'
                                >
                                    <label>{member.relationship}</label>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label='原籍户口类别'
                                >
                                    <label>{member.originType}</label>
                                </Form.Item>
                                < Form.Item
                                    {...formItemLayout}
                                    label='户口所在地'
                                >
                                    <label>{member.originAddress}</label>
                                </Form.Item>

                                {
                                    formType !== 'Newborn'
                                    &&
                                    <Form.Item
                                        {...formItemLayout}
                                        label='迁入后户口性质'
                                    >
                                        <label>{member.modifiedType}</label>
                                    </Form.Item>
                                }
                                {
                                    formType !== 'Newborn'
                                    &&
                                    <Form.Item
                                        {...formItemLayout}
                                        label='本市入户地址'
                                    >
                                        <label>{member.modifiedAddress}</label>
                                    </Form.Item>
                                }
                            </Panel>
                        })}
                    </Collapse>
                </Card>

                <Button onClick={() => this.previous()} style={{ float: "left" }}>上一步</Button>
                <Button onClick={() => this.submit()} type='primary' style={{ float: "right" }}
                    disabled={posting}
                >提交</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.householdForm,
        ...state.householdRegister
    };
};

export default connect(mapStateToProps)(HouseholdConfirm);