import * as idNumberUltil from '../ultilities/idNumberUltil';
import moment from 'moment';

const defaultStatus = 'process';

const initialState = {
    last: 0,
    current: 0,
    posting: false,
    status: defaultStatus,
    basicInfo: {},
    residenceInfo: {
        arriveDate: new moment().add(-12, 'M'),
        months:12
    }
}

const residenceregister = (state = initialState, action) => {
    if (action.type === 'PREVIOUS') {
        return {
            ...state,
            current: state.last,
            last: --state.last,
            status: defaultStatus
        };
    }

    if (action.type === 'SUBMIT_BASIC_INFO') {
        let basic = action.basicInfo;
        basic.birthDate = idNumberUltil.getBirthDate(basic.idNumber);

        return { ...state, basicInfo: basic, current: 1, last: state.current }
    }

    if (action.type === 'SUBMIT_RESIDENCE_INFO') {
        return { ...state, residenceInfo: action.residenceInfo, current: 2, last: state.current }
    }

    if (action.type==='SUBMIT_FROM') {
        return {
            ...state,
            posting: true
        };
    }

    if (action.type ==='SUBMIT_FINISH') {
        let status = 'finish'
        let current = state.current + 1;
        let posting = true;
        if (action.error) {
            status = 'error';
            current -= 1;
            posting = false;
        }
        return {
            ...state,
            posting: posting,
            current: current,
            status: status
        }

    }

    return state;
}

export const residenceRegister = residenceregister;