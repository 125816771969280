import React from 'react';
import { Steps } from 'antd';
import { connect } from 'react-redux'

import DefaultLayout from '../../layouts/DefaultLayout';

import Applicant from '../../components/HouseholdApplicant';
import ModifyItem from '../../components/HouseholdMdifyItem';
import Parents from '../../components/ParentsForm';
import Confirm from '../../components/HouseholdModifyConfirm';
import Success from '../../components/SuccessPage';

const Step = Steps.Step;

class HouseholdModify extends React.Component {
    render() {
        const { current, status } = this.props;

        const steps = [{
            title: '申请人信息',
            content: '填写申请人信息'
        }, {
            title: '变更项目',
                content: '填写变更项目'
        }, {
            title: '监护人信息',
                content: '填写监护人信息'
        }, {
            title: '确认信息',
            content: '确认户口项目修改信息'
            },{
                title: '完成',
                content: '户口项目修改申请填报完毕'
            }]

        return (
            <DefaultLayout title="变更户口项目">
                <div style={{ maxWidth: "720px", padding: "5px", margin: "0 auto" }}>
                    <Steps current={current} status={status}>
                        {steps.map(item => <Step key={item.title} title={item.title} />)}
                    </Steps>
                    <div>
                        {
                            current === 0
                            && <Applicant formType='Modify' />
                        }

                        {
                            current === 1
                            && <ModifyItem />
                        }

                        {
                            current === 2
                            && <Parents />
                        }

                        {
                            current === 3
                            && <Confirm/>
                        }

                        {
                            current === 4
                            && <Success />
                        }
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.householdForm,
        ...state.householdModify
    };
};
export default connect(mapStateToProps)(HouseholdModify);