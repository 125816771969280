import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Select, Input } from 'antd';

import * as data from '../const';
import { changeItem, previous } from '../actions/householdmodify';
import * as action from '../actions/householdmodify';

const { Option } = Select;

class HouseholdModifyItem extends React.Component {
    handleSubmit(e) {
        e.preventDefault();

        const { validateFields } = this.props.form;
        const { dispatch, age } = this.props;

        validateFields((err, values) => {
            if (!err) {
                let next = 2;
                if (age >= 18) {
                    next = 3;
                }

                dispatch(action.submitItem(values.item, values.modifiedValue, next));
                window.scrollTo(0, 0);
            }
        });
    }

    changeItem(value) {
        const { dispatch, applicant } = this.props;
        let prop = data.modifyItemData
            .filter(item => item.item === value)[0]
            .applicantProperty;

        dispatch(action.changeItem(value, applicant[prop]));
    }

    prev() {
        const { dispatch } = this.props;

        dispatch(action.previous);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        const { item, modifiedValue } = this.props;

        return (
            <div>
                <Form className="modify-item-form" onSubmit={e => this.handleSubmit(e)}>
                    <Form.Item
                        {...formItemLayout}
                        label='选择变更项目'
                    >
                        {getFieldDecorator('item', {
                            rules: [{ required: true, message: '请选择变更项目。' }],
                            initialValue: item
                        })(
                            <Select placeholder='选择变更项目' onChange={e => this.changeItem(e)}>
                                {
                                    data.modifyItemData.map(item => <Option key={item.item}>{item.item}</Option>)
                                }
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label='变更后登记内容'
                    >
                        {getFieldDecorator('modifiedValue', {
                            rules: [{ required: true, message: '请填写变更后内容。' }],
                            initialValue: modifiedValue
                        })(
                            <Input placeholder='变更后内容' />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button onClick={() => this.prev()}>上一步</Button>
                        <Button type="primary" htmlType="submit" style={{ float: "right" }}>下一步</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.householdModify,
        ...state.householdForm
    };
};

let ModifyItem = Form.create({ name: 'household_item_modify' })(HouseholdModifyItem);
ModifyItem = connect(mapStateToProps)(ModifyItem);

export default ModifyItem;