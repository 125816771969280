import React, { Component } from 'react';
import {
    Form,
    Input,
    Select,
    DatePicker,
    Button,
    Collapse,
    Card,
    Popconfirm,
    message,
    Icon,
    BackTop
} from 'antd';
import { connect } from 'react-redux';

import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';

import * as action from '../actions/household';
import * as data from '../const';

import * as idNumberUltil from '../ultilities/idNumberUltil';

const Panel = Collapse.Panel;
const { Option } = Select;

class HouseholdMember extends React.Component {
    handleSubmit(e) {
        e.preventDefault();

        const { validateFields, resetFields } = this.props.form;
        const { dispatch, members } = this.props;
        validateFields((err, values) => {
            if (!err) {
                if (moment.isMoment(values.idNumber)) {
                    values.idNumber = values.idNumber.format('YYYY年M月D日 H:m');
                }

                if (values.idNumber) {
                    values.idNumber = values.idNumber.toUpperCase();
                }

                dispatch(action.add(values));
                resetFields();
                message.success('添加成功', 1);
            }
        });
    };

    handleRemove(id) {
        const { dispatch } = this.props;
        dispatch(action.remove(id));
        message.success('移除成功', 1);
    }

    previous() {
        const { dispatch } = this.props;
        dispatch(action.previous());
        window.scrollTo(0, 0);
    }

    next() {
        const { dispatch } = this.props;
        dispatch(action.submitMembers());
        window.scrollTo(0, 0);
    }

    changeGender(value) {
        const { dispatch } = this.props;
        const { resetFields } = this.props.form
        dispatch(action.changeGender(value));
        resetFields(['relationship']);
    }

    componentDidMount() {
        const { members } = this.props;

        if (members.length > 0) {
            window.scroll(0, window.innerHeight);
        }
    };

    render() {
        //可以添加被申请人限制
        let limit = 4;

        const { members, relationships } = this.props;

        const { getFieldDecorator } = this.props.form;

        const { formType } = this.props;

        if (formType === 'Deregister') {
            limit = 7;
        }

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        const customPanelStyle = {
            background: '#f7f7f7',
            borderRadius: 4,
            marginBottom: 24,
            border: 0,
            overflow: 'hidden',
            textAlign: 'left'
        };

        return (
            <div>
                <BackTop />
                <Form className="household-member" onSubmit={e => this.handleSubmit(e)}>
                    <Form.Item
                        {...formItemLayout}
                        label={formType === 'Newborn' ? '新生儿姓名' : '被申请人姓名'}
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: formType === 'Newborn' ? '请填写新生儿姓名' : '请填写被申请人姓名'
                            }]
                        })(
                            <Input placeholder={formType === 'Newborn' ? '新生儿姓名' : '被申请人姓名'} />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="性别"
                    >
                        {getFieldDecorator('gender', {
                            rules: [{ required: true, message: '请选择性别。' }]
                        })(
                            <Select placeholder="请选择性别" onChange={e => this.changeGender(e)}>
                                {
                                    data.genderData.map(gender => <Option key={gender}>{gender}</Option>)
                                }
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="与被申请人关系"
                    >
                        {getFieldDecorator('relationship', {
                            rules: [{ required: true, message: '请选择与被申请人关系。' }]
                        })(
                            <Select placeholder="与被申请人关系">
                                {
                                    relationships.filter(relationship => formType === undefined || relationship.cate === formType || formType === 'Deregister')
                                        .map(relationship => <Option key={relationship.value}>{relationship.text}</Option>)
                                }
                            </Select>
                        )}
                    </Form.Item>

                    {
                        //2019-03-26 新生儿入户不填写出生日期 by lujj

                        formType === 'Newborn' && null
                        //&& <Form.Item
                        //    {...formItemLayout}
                        //    label="出生日期"
                        //>
                        //    {getFieldDecorator('idNumber', {
                        //        rules: [{
                        //            required: true, message: '请填写被申请人出生日期。'
                        //        }]
                        //    })(
                        //        <DatePicker format='YYYY-M-D H:m'
                        //            showTime
                        //            style={{ maxWidth: 720, width: '100%' }}
                        //            locale={locale}
                        //        />
                        //    )}
                        //</Form.Item>
                    }

                    {
                        formType !== 'Newborn'
                        && <Form.Item
                            {...formItemLayout}
                            label="被申请人身份证号码"
                        >
                            {getFieldDecorator('idNumber', {
                                rules: [{
                                    required: true, message: '身份证号码必填'
                                }, {
                                    validator: (rule, value, callback) => {
                                        const errors = [];

                                        if (value && value.length > 0) {
                                            let validate = idNumberUltil.validator(value);
                                            if (!validate.validated) {
                                                errors.push(new Error('身份证号码格式不正确'));
                                            }
                                        }

                                        let count = members
                                            .filter(member => member.idNumber === value)
                                            .length;
                                        if (count > 0) {
                                            errors.push(new Error('身份证号码重复。'));
                                        }
                                        callback(errors.slice(0, 1));
                                    }
                                }]
                            })(
                                <Input placeholder="被申请人身份证号码" />
                            )}
                        </Form.Item>
                    }

                    {
                        formType !== 'Newborn'
                        && <Form.Item
                            {...formItemLayout}
                            label="户口所在地"
                        >
                            {getFieldDecorator('originAddress', {
                                rules: [{ required: true, message: '请填写被申请人户口所在地' }]
                            })(
                                <Input placeholder="被申请人户口所在地" />
                            )}
                        </Form.Item>
                    }

                    {
                        formType !== 'Newborn'
                        && <Form.Item
                            {...formItemLayout}
                            label="原籍户口类别"
                        >
                            {getFieldDecorator('originType', {
                                rules: [{ required: true, message: '请填写被申请人原籍户口类别' }]
                            })(
                                <Select placeholder="被申请人原籍户口类别">
                                    {
                                        data.houseHoldTypes.map(type => <Option key={type.value}>{type.text}</Option>)
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    }

                    {
                        formType !== 'Deregister'
                        && <Form.Item
                            {...formItemLayout}
                            label={formType === 'Newborn' ? '出生医院' : "本市入户地址"}
                        >
                            {getFieldDecorator(formType === 'Newborn' ? 'originAddress' : 'modifiedAddress', {
                                rules: [{ required: true, message: formType === 'Newborn' ? '请填写被申请人出生医院' : '请填写被申请人本市入户地址' }]
                            })(
                                <Input placeholder={formType === 'Newborn' ? '出生医院' : '被申请人本市入户地址'} />
                            )}
                        </Form.Item>
                    }

                    {
                        formType !== 'Deregister'
                        && <Form.Item
                            {...formItemLayout}
                            label="迁入后户口性质"
                        >
                            {getFieldDecorator(formType === 'Newborn' ? 'originType' : 'modifiedType', {
                                rules: [{ required: true, message: '请填写被申请人迁入后户口性质' }]
                            })(
                                <Select placeholder="被申请人迁入后户口性质">
                                    {
                                        data.houseHoldTypes.map(type => <Option key={type.value}>{type.text}</Option>)
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    }

                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button type="primary" htmlType="submit" disabled={members.length >= limit}>添加</Button>
                    </Form.Item>
                </Form>

                <div style={{ marginBottom: 35, textAlign: "left", clear: "both" }}>
                    <Card title={formType === 'Newborn' ? '新生儿信息' : '被申请人信息'} bordered={false} style={{ maxWidth: 720 }}>
                        <Collapse
                            bordered={false}
                            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                        >
                            {members.map(member => {
                                return <Panel header={member.name}
                                    style={customPanelStyle}
                                    key={member.id}
                                >
                                    <div style={{ textAlign: "right" }}>
                                        <Popconfirm placement="left"
                                            title={'确定移除被申请人：' + member.name + ' 吗?'}
                                            onConfirm={() => this.handleRemove(member.id)}
                                            okText="确定" cancelText="取消"
                                            icon={
                                                <Icon type="question-circle-o" style={{ color: 'red' }} />
                                            }
                                        >
                                            <Button type='danger'>移除</Button>
                                        </Popconfirm>
                                    </div>
                                    <Form.Item
                                        {...formItemLayout}
                                        label='姓名'
                                    >
                                        <label>{member.name}</label>
                                    </Form.Item>
                                    <Form.Item
                                        {...formItemLayout}
                                        label='性别'
                                    >
                                        <label>{member.gender}</label>
                                    </Form.Item>
                                    {
                                        //2019-03-26 新生儿登记不填写出生日期 by lujj
                                        
                                        formType !== 'Newborn' &&
                                        <Form.Item
                                            {...formItemLayout}
                                            label='身份证号码'
                                        >
                                            <label>{member.idNumber}</label>
                                        </Form.Item>

                                        //<Form.Item
                                        //    {...formItemLayout}
                                        //    label={formType === 'Newborn' ? '出生日期' : '身份证号码'}
                                        //>
                                        //    <label>{member.idNumber}</label>
                                        //</Form.Item>
                                    }
                                    <Form.Item
                                        {...formItemLayout}
                                        label='关系'
                                    >
                                        <label>{member.relationship}</label>
                                    </Form.Item>
                                    <Form.Item
                                        {...formItemLayout}
                                        label='原籍户口类别'
                                    >
                                        <label>{member.originType}</label>
                                    </Form.Item>
                                    < Form.Item
                                        {...formItemLayout}
                                        label='户口所在地'
                                    >
                                        <label>{member.originAddress}</label>
                                    </Form.Item>

                                    {
                                        formType !== 'Newborn'
                                        &&
                                        <Form.Item
                                            {...formItemLayout}
                                            label='迁入后户口性质'
                                        >
                                            <label>{member.modifiedType}</label>
                                        </Form.Item>
                                    }
                                    {
                                        formType !== 'Newborn'
                                        &&
                                        <Form.Item
                                            {...formItemLayout}
                                            label='本市入户地址'
                                        >
                                            <label>{member.modifiedAddress}</label>
                                        </Form.Item>
                                    }
                                </Panel>
                            })}
                        </Collapse>
                    </Card>
                </div>

                <Button onClick={() => this.previous()} style={{ float: "left" }}>上一步</Button>
                <Button onClick={() => this.next()} type='primary' style={{ float: "right" }} disabled={members.length === 0}>下一步</Button>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        ...state.householdRegister
    };
};

let Member = Form.create({ name: 'household_member' })(HouseholdMember);
Member = connect(mapStateToProps)(Member);

export default Member;