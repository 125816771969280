import moment from 'moment';

const root = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
const crc = ['1', '0', 'X', '9' ,'8' ,'7' ,'6' ,'5' ,'4' ,'3' ,'2']

export const splitor = idNumber => {
    if (idNumber.length!==18) {
        throw '身份证号码必须为18位';
    }
    
    const id = {
        code: idNumber,
        divisionCode: idNumber.substr(0, 6),
        birthDate: idNumber.substr(6, 8),
        orderCode: idNumber.substr(14, 3),
        checkCode: idNumber.substr(17, 1).toUpperCase()
    };

    return id;
}

export const validator = idNumber => {
    try {
        let id = splitor(idNumber);

        const regex = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X|x)$/;
        if (!regex.test(id.code)) {
            return {
                validated: false,
                message: '身份证号码格式错误'
            };
        }

        if (!new moment(id.birthDate, 'YYYYMMDD').isValid()) {
            return {
                validated: false,
                message:'日期格式错误'
            };
        }

        let checkSum = 0;
        for (var i = 0; i < 17; i++) {
            checkSum+= id.code[i] * root[i];
        }

        let index = checkSum % 11;

        let match = id.checkCode === crc[index];
        if (!match) {
            return {
                validated: false,
                message:'校验码错误'
            }
        }
        return { validated: true }

    } catch (e) {
        return {
            validated: false,
            message: e.message
        }
    }
}

export const getBirthDate = idNumber => {
    try {
        let id = splitor(idNumber);
        return new moment(id.birthDate)
            .format('YYYY年M月D日');
    } catch (e) {
        throw e;
    }
}

export const getAge = idNumber => {
    try {
        let id = splitor(idNumber);
        const date = new moment(id.birthDate, 'YYYYMMDD');
        let age= moment().diff(date,'year');

        return age;

    } catch (e) {
        throw e;
    }
}