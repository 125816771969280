import React, { Component } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

const {
    Header, Footer, Content,
} = Layout;

class DefaultLayout extends React.Component {
    render() {
        const { title, children } = this.props;

        return (
            <Layout style={{ minHeight:'100vh' }}>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <Row>
                        <Col span={4}>
                            <Link to='/'>
                                <Button type="primary" icon="left">
                                </Button>
                            </Link>
                        </Col>
                        <Col span={16} className='title'>{title}</Col>
                        <Col span={4}></Col>
                    </Row>
                </Header>
                <Content style={{ marginTop: 64, marginBottom:15 }}>
                    <div style={{ paddingLeft:24, paddingRight: 24 }}>{children}</div>
                </Content>
            </Layout >
        );
    }
}

export default DefaultLayout;