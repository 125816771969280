import React from 'react';
import { Button, Card, message } from 'antd'
import { connect } from 'react-redux';
import axios from 'axios';

import * as api from '../api'; 

import * as action from '../actions/householdmodify';

class HouseholdModifyConfirm extends React.Component {
    prev() {
        const { dispatch } = this.props;
        dispatch(action.previous);
        window.scrollTo(0, 0);
    }

    async submit() {
        const { dispatch, applicant, item, originalValue, modifiedValue, father, mother } = this.props;
        dispatch(action.submitForm());

        let loading = message.loading('正在提交资料...', 5);
        try {
            let response = await axios(api.household.modify, {
                method: 'POST',
                data: {
                    ...applicant,
                    subject: item,
                    before: originalValue,
                    after: modifiedValue,
                    father: father,
                    mother: mother
                }
            });

            if (response.data.success) {
                dispatch(action.submitFinish(false));
            }
            else {
                dispatch(action.submitFinish(true));
                message.error(response.data.message);
            }
        } catch (e) {
            dispatch(action.submitFinish(true));
            message.error(e.message, 5);
        }
        finally {
            loading();
        }
    }

    render() {
        const { applicant, item,
            originalValue, modifiedValue,
            father, mother,
            posting } = this.props;

        return (
            <div>
                <Card title='申请人信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                >
                    <p>申请人姓名：{applicant.name}</p>
                    <p>性别：{applicant.gender}</p>
                    <p>身份证号码：{applicant.idNumber}</p>
                    <p>户口所在地：{applicant.address}</p>
                    <p>户口性质：{applicant.type}</p>
                </Card>

                <Card title='变更项目信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                >
                    <p>变更项目：{item}</p>
                    <p>变更前登记内容：{originalValue}</p>
                    <p>变更后登记内容：{modifiedValue}</p>
                </Card>

                {
                    father && mother &&
                    <Card title='监护人信息'
                        style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                    >
                        <p>父亲姓名：{father}</p>
                        <p>母亲姓名：{mother}</p>
                    </Card>
                }

                <Button onClick={() => this.prev()} style={{ float: "left" }}>上一步</Button>
                <Button onClick={() => this.submit()} type='primary' style={{ float: "right" }}
                    disabled={posting}
                >提交</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.householdForm,
        ...state.householdModify
    };
};

export default connect(mapStateToProps)(HouseholdModifyConfirm);