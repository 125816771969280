//const host = '/';

export const household = {
    register: '/api/household/submit',
    modify: '/api/household-modification/submit',
    deregister: '/api/household-deregistration/submit'
}

export const residence = {
    apply: '/api/residence/submit'
}

export const immigration = {
    apply: '/api/immigration/submit'
}