import React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form, message } from 'antd';
import axios from 'axios';

import moment from 'moment';

import * as api from '../api';

import * as action from '../actions/residence';

import * as data from '../const';

class ResidenceConfirm extends React.Component {
    async submit() {
        const { dispatch, basicInfo, residenceInfo } = this.props;

        let formData = { ...basicInfo, ...residenceInfo };

        formData.arrive = null;
        formData.registryProvince = formData.registryArea[0];
        formData.registryCity = formData.registryArea[1];
        formData.registryDistrict = formData.registryArea[2];

        if (data.residenceApplyTypeData.has(formData.residenceApplyType)) {
            formData.type = data.residenceApplyTypeData.get(formData.residenceApplyType);
        }

        formData.marriage = -1;
        formData.political = -1;
        formData.householdType = -1;
        //if (formData.registryType) {
        //    formData.registryType = data.residenceRegistryTypeData.findIndex(x => x == formData.registryType);
        //}
        //else {
        //    formData.registryType = 99
        //}
        formData.registryType = 99;
        formData.education = -1;

        if (data.residenceType.has(formData.residenceType)) {
            formData.residenceType = data.residenceType.get(formData.residenceType);
        }
        if (data.residenceReasonData.has(formData.reason)) {
            formData.reason = data.residenceReasonData.get(formData.reason);
        }
        //formData.status = data.residenceStatusData.findIndex(x => x == formData.status);
        formData.status = -1;

        if (residenceInfo.months === undefined) {
            formData.months = 0;
        }

        dispatch(action.submitForm());
        let loading = message.loading('正在提交资料...', 5);
        try {
            let response = await axios.post(api.residence.apply, formData);

            if (response.data.success) {
                dispatch(action.submitFinish(false));
            }
            else {
                dispatch(action.submitFinish(true));
                message.error(response.data.message);
            }
        } catch (e) {
            dispatch(action.submitFinish(true));
            message.error(e.message, 5);
        }
        finally {
            loading();
        }
    }

    prev() {
        const { dispatch } = this.props;
        dispatch(action.previous());
        window.scrollTo(0, 0);
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const { basicInfo, residenceInfo, posting } = this.props;

        return (
            <div>
                <Card title='基本信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                >
                    <Form.Item label='姓名'
                        {...formItemLayout}
                    >
                        <label>{basicInfo.name}</label>
                    </Form.Item>
                    {/*<Form.Item label='性别'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.gender}</label>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label='民族'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.nation}</label>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label='身份证号码'
                        {...formItemLayout}
                    >
                        <label>{basicInfo.idNumber}</label>
                    </Form.Item>
                    {/*<Form.Item label='出生日期'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.birthDate}</label>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label='婚姻状况'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.marriage}</label>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label='政治面貌'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.political}</label>*/}
                    {/*</Form.Item>*/}

                    <Form.Item label='户籍所在地'
                        {...formItemLayout}
                    >
                        <label>{basicInfo.registryArea.join()}</label>
                    </Form.Item>
                    <Form.Item label='详细地址'
                        {...formItemLayout}
                    >
                        <label>{basicInfo.registryAddress}</label>
                    </Form.Item>
                    {/*<Form.Item label='户口类别'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.householdType}</label>*/}
                    {/*</Form.Item>*/}

                    {/*{*/}
                    {/*    basicInfo.registryType &&*/}
                    {/*    <Form.Item label='户籍地类别'*/}
                    {/*        {...formItemLayout}*/}
                    {/*    >*/}
                    {/*        <label>{basicInfo.registryType}</label>*/}
                    {/*    </Form.Item>*/}
                    {/*}*/}

                    {/*<Form.Item label='文化程度'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{basicInfo.education}</label>*/}
                    {/*</Form.Item>*/}
                </Card>

                <Card title='居住信息'
                    style={{ maxWidth: 720, textAlign: "left", marginTop: 15, marginBottom: 15 }}
                >
                    <Form.Item label='现居地址'
                        {...formItemLayout}
                    >
                        <label>{residenceInfo.address}</label>
                    </Form.Item>
                    {/*<Form.Item label='与房主关系'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{residenceInfo.relationship}</label>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label='房主姓名'
                        {...formItemLayout}
                    >
                        <label>{residenceInfo.ownerName}</label>
                    </Form.Item>
                    <Form.Item label='房主电话号码'
                        {...formItemLayout}
                    >
                        <label>{residenceInfo.ownerPhone}</label>
                    </Form.Item>
                    {/*{*/}
                    {/*    residenceInfo.ownerIDNumber &&*/}
                    {/*    <Form.Item label='房主身份证号码：'*/}
                    {/*        {...formItemLayout}*/}
                    {/*    >*/}
                    {/*        <label>{residenceInfo.ownerIDNumber}</label>*/}
                    {/*    </Form.Item>*/}
                    {/*}*/}
                    <Form.Item label='居住处所'
                        {...formItemLayout}
                    >
                        <label>{residenceInfo.residenceType}</label>
                    </Form.Item>
                    <Form.Item label='居住事由'
                        {...formItemLayout}
                    >
                        <label>{residenceInfo.reason}</label>
                    </Form.Item>
                    {/*<Form.Item label='居住或从业'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{residenceInfo.status}</label>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label='来本址时间'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>{residenceInfo.arriveDate.format('YYYY年M月D日')}</label>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label='拟居住期限'*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    <label>*/}
                    {/*        {residenceInfo.months === 0 || residenceInfo.months === undefined ?*/}
                    {/*            '一年以上' : residenceInfo.months + '月'*/}
                    {/*        }*/}
                    {/*    </label>*/}
                    {/*</Form.Item>*/}
                </Card>
                <p>联系电话：{basicInfo.phone}</p>
                <p>申办类型：{basicInfo.residenceApplyType}</p>
                
                <Button onClick={() => this.prev()} style={{ float: "left" }}>上一步</Button>
                <Button onClick={() => this.submit()} type='primary' style={{ float: "right" }}
                    disabled={posting}
                >
                    提交
                </Button>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(ResidenceConfirm);