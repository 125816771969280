import React from 'react';
import { Steps } from 'antd';

import DefaultLayout from '../../layouts/DefaultLayout';

import { connect } from 'react-redux';

import ImmigrationApplicant from '../../components/ImmigrationApplicant';
import ImmigrationBussiness from '../../components/ImmigrationBussiness';
import ImmigrationConfirm from '../../components/ImmigrationConfirm';
import Success from '../../components/SuccessPage';

const Step = Steps.Step;

const steps = [{
    title: '填写申请人信息',
    content: '填写申请人信息'
}, {
    title: '选择办理业务',
        content: '选择办理业务'
}, {
    title: '确认填报信息',
    content: '确认填报信息'
}, {
    title: '完成',
    content: '完成出入境证件申请填报'
}]

class Immigration extends React.Component {
    render() {
        const { current, status } = this.props;

        return (
            <DefaultLayout title="出入境证件申请">
                <div style={{ maxWidth: "720px", padding: "5px", margin: "0 auto" }}>
                    <Steps current={current} status={status}>
                        {steps.map(item => <Step key={item.title} title={item.title} />)}
                    </Steps>

                    {
                        current === 0 &&
                        <ImmigrationApplicant/>
                    }

                    {
                        current === 1 &&
                        <ImmigrationBussiness/>
                    }

                    {
                        current === 2 &&
                        <ImmigrationConfirm/>
                }
                
                    {
                        current === 3 &&
                        <Success />
                    }
                </div>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}
export default connect(mapStateToProps)(Immigration);