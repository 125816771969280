const defaultStatus = 'process';

const initialState = () => {
    return ({
        applicant: {},
        last:0,
        current: 0,
        posting: false,
        status: defaultStatus
    });
};

const householdform = (state = initialState(), action) => {
    switch (action.type) {
        case 'PREVIOUS':
            return {
                ...state,
                current: state.last,
                last: --state.last,
                status: defaultStatus
            };


        case 'SUBMIT_APPLICANT':
            return {
                ...state,
                applicant: action.applicant,
                last: state.current,
                current: action.current
            };

        case 'SUBMIT_MEMBERS':
            return {
                ...state,
                last: state.current,
                current: action.current
            };
        
        case 'SUBMIT_FORM':
            return {
                ...state,
                posting: true
            };
        case 'SUBMIT_FINISH':
            let status = 'finish'
            let current = action.current;
            let posting = true;
            if (action.error) {
                status = 'error';
                current -= 1;
                posting = false;
            }
            return {
                ...state,
                posting: posting,
                current: current,
                status: status
            }

        case 'SUBMIT_MODIFY_ITEM':
            return {
                ...state,
                last: state.current,
                current:action.next,
            }

        case 'SUBMIT_MODIFY_PARENTS':
            return {
                ...state,
                last: state.current,
                current: 3
            }

        default:
            return state;
    }
}

export const householdForm= householdform;