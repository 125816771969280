import * as data from '../const';

const initialState = () => {
    return ({
        members: [],
        relationships: relationshipData[data.genderData[0]],
    });
};

const householdregister = (state = initialState(), action) => {
    switch (action.type) {
        case 'CHANGE_GENDER':
            return { ...state, relationships: relationshipData[action.gender] }
        
        case 'ADD_MEMBER':
            state.members.push(action.member);
            return { ...state };

        case 'REMOVE_MEMBER':
            var left = state.members.filter(member => member.id !== action.id);
            return { ...state, members: left };

        case 'SUBMIT_MEMBERS':
            return {
                ...state
            };

        default:
            return state;
    }
}

export const relationshipData = {
    男: [
        { value: '本人', text: '本人' },
        { value: '夫妻', text: '夫妻' },
        { value: '祖父', text: '祖父' },
        { value: '外祖父', text: '外祖父' },
        { value: '孙子', text: '孙子' },
        { value: '曾孙', text: '曾孙' },
        { value: '女婿', text: '女婿' },
        { value: '公公', text: '公公' },
        //{ value: '岳父', text: '岳父' },
        { cate: 'Newborn', value: '父子', text: '父子' },
        { cate: 'Newborn', value: '母子', text: '母子' }
    ],
    女: [
        { value: '本人', text: '本人' },
        { value: '夫妻', text: '夫妻' },
        { value: '祖母', text: '祖母' },
        { value: '外祖母', text: '外祖母' },
        { value: '孙女', text: '孙女' },
        { value: '曾孙女', text: '曾孙女' },
        { value: '儿媳', text: '儿媳' },
        { value: '婆婆', text: '婆婆' },
        //{ value: '岳母', text: '岳母' },
        { cate: 'Newborn', value: '父女', text: '父女' },
        { cate: 'Newborn', value: '母女', text: '母女' }
    ]
}

export const householdRegister = householdregister;