import React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Select,
    Form,
    Alert
} from 'antd';

import * as data from '../const';

const { Option } = Select;

class ImmigrationBussiness extends React.Component {
    prev() {
        const { dispatch } = this.props;
        dispatch({
            type: 'PREVIOUS'
        });
        window.scrollTo(0, 0);
    }

    handleSubmit = e => {
        e.preventDefault();

        const { validateFields } = this.props.form;
        const { dispatch } = this.props;

        validateFields((err, values) => {
            if (!err) {
                dispatch({
                    type: 'SUBMIT_BUSSINESS',
                    current: 2,
                    bussiness: values
                });
                window.scrollTo(0, 0);
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { passport,hk,tw } = this.props;
        
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item
                    {...formItemLayout}
                    label='普通护照'
                    extra={<Alert message='如无需办理则留空' />}
                >
                    {getFieldDecorator('passport', {
                        initialValue: passport
                    })(
                        <Select placeholder='请选择需要办理的业务' allowClear>
                        {
                            data.immigrationPassportData.map(item => <Option key={item}>{item}</Option>)
                        }
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label='港澳通行证'
                    extra={<Alert message='如无需办理则留空' />}
                >
                    {getFieldDecorator('hk', {
                        initialValue: hk
                    })(
                        <Select placeholder='请选择需要办理的业务' allowClear>
                        {
                            data.immigrationHK.map(item => <Option key={item}>{item}</Option>)
                        }
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label='台湾通行证'
                    extra={<Alert message='如无需办理则留空' />}
                >
                    {getFieldDecorator('tw', {
                        initialValue: tw
                    })(
                        <Select placeholder='请选择需要办理的业务' allowClear>
                        {
                            data.immigrationTW.map(item => <Option key={item}>{item}</Option>)
                        }
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    {...formItemLayoutWithOutLabel}
                >
                    <Button onClick={() => this.prev()} style={{ float: "left" }}>上一步</Button>
                    <Button htmlType="submit" type='primary' style={{ float: "right" }}>下一步</Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return { ...state }
}

let Bussiness = Form.create({ name: 'immigration_Bussiness' })(ImmigrationBussiness);
export default connect(mapStateToProps)(Bussiness);
