import { createStore } from 'redux';

import immigrationRegister from '../reducers/immigrationregister';

const rootReducer = (state, action) => {
    if (action.type === 'RESET_FORM') {
        state = undefined
    }

    return immigrationRegister(state, action)
}

const store = createStore(rootReducer);
export default store;