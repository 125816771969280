import React, { Component } from 'react';
import { Row, Col, Icon } from 'antd';
import HomeLayout from '../layouts/HomeLayout';
import { Link } from 'react-router-dom';

import * as action from '../actions/app';

class Home extends React.Component {
    constructor(props) {
        super(props);
        //重置所有stores状态
        action.resetState();
    }

    render() {
        return (
            <HomeLayout title="表单申报">
                <div>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Link to="/form/residence-register">
                                <div className="home-icon">
                                    <div><Icon type="idcard" theme="twoTone" /></div>
                                    <div>居住证</div>
                                </div>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Link to="/form/newborn">
                                <div className="home-icon">
                                    <div><Icon type="home" theme="twoTone" /></div>
                                    <div>新生儿入户</div>
                                </div>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Link to="/form/household">
                                <div className="home-icon">
                                    <div><Icon type="contacts" theme="twoTone" /></div>
                                    <div>全域迁移</div>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Link to="/form/immigration-register">
                                <div className="home-icon">
                                    <div><Icon type="reconciliation" theme="twoTone" /></div>
                                    <div>出入境证件</div>
                                </div>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Link to="/form/household-modify">
                                <div className="home-icon">
                                    <div><Icon type="profile" theme="twoTone" /></div>
                                    <div>户口<br/>项目变更</div>
                                </div>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Link to="/form/deregister">
                                <div className="home-icon">
                                    <div><Icon type="file-excel" theme="twoTone" /></div>
                                    <div>注销户口</div>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </HomeLayout>
        );
    }
}

export default Home;